import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { ContainerSVGT, MainFont, HomeColor, GivingColor, SixthPage } from '../components/Sampler'

export const GivingPageTemplate = ({
  backgroundimage,
}) => {

  const [more, setMore] = useState(false)

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* Font Imports */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

        {/* GIVING IMAGE HEADING */}
        <div style={{ display: 'grid' }}>

          {/* Colored Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={GivingColor} stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* "Giving" Text */}
          <div style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '3.2em',
            color: '#FFFFFF',
            fontFamily: MainFont,
            fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
            letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
          }}>
            GIVING
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">

          {/* Giving Page Explanation - ONLY FOR SITE SAMPLER */}
          {/* <div style={{
            fontSize: '1.1em',
            lineHeight: '2em',
            letterSpacing: '0.06em',
            fontFamily: 'Poppins',
            marginTop: '8px',
            marginBottom: '30px',
            textAlign: 'center',
          }}>
            <i>This section of your website lets viewers reach out to you. The giving form links to your email, and you'll get all the information they put in here sent straight to you!</i>
          </div>
          <div style={{
            fontSize: '.9em',
            lineHeight: '2em',
            letterSpacing: '0.06em',
            fontFamily: 'Poppins',
            // color: GivingColor,
            // display: 'flex',
            // flexDirection: 'column',
            // marginLeft: '25%',
            // marginRight: '25%',
            marginBottom: '50px',
            textAlign: 'center',
          }}>
            <i>(This explanatory text will not appear on your website, only the form below. Don't use this sample to giving Church Sites Online. Use the form on the <a href="https://churchsites.online/" target="_blank">Church Sites Online home page</a> for that, instead.)</i>
          </div> */}
          

            
          <a href="https://7a522507.churchtrac.com/give" target="_blank">
            <button className="give-button" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }}>
              Give Online
            </button>
          </a>

          {more === false ? 

            <button className="give-button" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }} onClick={() => setMore(true)}>
              Send a Check
            </button>
          :
          <div>
            <button className="give-button" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }} onClick={() => setMore(false)}>
              Send a Check
            </button>
              <p className="check-address">Thank you for considering giving to Poplar Branch Baptist Church! <br></br><br></br>You can address your checks to <b>Poplar Branch Baptist Church</b> and your envelopes to <br></br><br></br><i>709 Poplar Branch Rd<br></br>Grandy, NC 27939</i></p>
          </div>
          }
        </div>
      </section>
      
    </div>
  )
}

GivingPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const GivingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <GivingPageTemplate
        backgroundimage={frontmatter.backgroundimage}
      />
    </Layout>
  )
}

GivingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default GivingPage

export const pageQuery = graphql`
  query GivingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "giving-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`